import React, {useCallback} from 'react'

export function usePopupTrigger() {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

    const onClick = useCallback((event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const onClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return {
        controlButton: {onClick},
        controlledPopover: {anchorEl, onClose, open: Boolean(anchorEl)}
    }
}


export type ControlButton<T> = (item: T) => React.MouseEventHandler

export interface ControlledDialog {
    onClose: () => void
    open: boolean
}

export interface ModalDialogState<T> {
    trigger: ControlButton<T>;
    dialog: ControlledDialog,
    item: T | undefined
}

export function useModalDialogState<T>(): ModalDialogState<T> {
    const [item, setItem] = React.useState<T>()

    const onClick = useCallback((item: T) =>
        (event: React.MouseEvent<Element>) => {
            setItem(item)
        }, [])

    const onClose = useCallback(() => {
        setItem(undefined)
    }, [])

    return {
        trigger: (item: T) => onClick(item),
        dialog: {open: item != null, onClose},
        item
    }
}
